import React, { useState } from 'react';
import Logo from '../logohs.png'
import './css/RegistrationForm.css'

const RegistrationForm = () => {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [licence, setLicence] = useState('');

  const handleSubmit = (event) => {
    // event.preventDefault();
    
        console.log('Formulaire soumis avec succès!');
  };

  return (
    <div className="inscription-form">
    <div className="logo-container">
        <img src={Logo} alt="Logo" />
      </div>
      <h2 className="title">INSCRIPTION</h2>
      <form onSubmit={handleSubmit} action='/inscription/subscription' method="post" >
        <div className="form-group">
          <label for="nom">Nom</label>
          <input
            type="text"
            id="nom" name="nom"
            value={nom}
            onChange={(event) => setNom(event.target.value)}
            placeholder="Entrez votre nom"
          />
        </div>
        <div className="form-group">
          <label for="prenom">Prénom</label>
          <input
            type="text"
            id="prenom" name="prenom"
            value={prenom}
            onChange={(event) => setPrenom(event.target.value)}
            placeholder="Entrez votre prénom"
          />
        </div>
        <div className="form-group">
          <label for="username">Identifiant</label>
          <input
            type="text"
            id="username"  name="username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            placeholder="Entrez votre identifiant"
          />
        </div>
        <div className="form-group">
          <label for="password">Mot de passe</label>
          <input
            type="password"
            id="password" name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Entrez votre mot de passe"
          />
        </div>
        <div className="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            id="email" name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder="Entrez votre email"
          />
        </div>
        <div className="form-group">
          <label for="licence">Numéro de licence</label>
          <input
            type="text"
            id="licence" name="licence"
            value={licence}
            onChange={(event) => setLicence(event.target.value)}
            placeholder="Entrez votre numéro de licence"
          />
        </div>
        <button className="submitButton" type="submit">S'inscrire</button>
      </form>
    </div>

  );
};

export default RegistrationForm;

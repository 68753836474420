import React from 'react';
import './css/DownloadPage.css'; // Import the CSS file

const DownloadPage = () => {
  const fileUrl = 'https://example.com/path/to/file.pdf'; // URL du fichier à télécharger
  const fileName = 'example.pdf'; // Nom du fichier à télécharger

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.click();
  };

  return (
    <div className="download-page">
      <div className="download-card">
        <h1>Télécharger le fichier</h1>
        <p>Cliquez sur le bouton pour télécharger le fichier :</p>
        <button className="download-btn" onClick={handleDownload}>Télécharger</button>
      </div>
    </div>
  );
};

export default DownloadPage;